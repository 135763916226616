.introductory-compaign {
	&-header {
		position: relative;
		background-color: rgba(0, 0, 0, 0.3);
		@include flex();
		height: 370px;
		width: 100%;
		
		@include responsive(xs) {
			height: 250px;
		}
		
		&-image {
			position: absolute;			
			object-fit: cover;
			width: 100%;
			height: 100%;
			z-index: -1;
		}
		
		&-title {
			color: $color-white;
			text-transform: uppercase;
		}
	}
	
	&-committee {
		&-items {
			@include flex();
			flex-wrap: wrap;
			margin: -40px;
			margin-top: 70px;
			
			@include responsive(xs) {
				margin-top: 35px;
			}
		}
		
		&-item {
			margin: 40px;
			max-width: 400px;
			max-height: 460px;

			@include responsive(xs) {
				width: 100%;
			}
			
			& > img {
				width: 100%;
				height: auto;
			}
		}
		
		&-button {
			@include flex();
			margin-top: 50px;
		}
	}
	
	&-videos {
		margin-top: 120px;
		
		@include responsive(xs) {
			margin-top: 70px;
		}
		
		&-inner {
			@include width(center);

			@include responsive(xs) {
				width: 100%;
				padding: 0 20px;
				margin-left: 0;
				margin-right: 0;
				box-sizing: border-box;
			}
		}
		
		&-items {
			@include flex();
			flex-wrap: wrap;
			margin: -40px;
			margin-top: -80px;
			
			@include responsive(xs) {
				width: 100%;
				margin: 0;
			}
		}
		
		&-item {
			margin: 40px;
			
			@include responsive(xs) {
				margin-left: 0;
				margin-right: 0;
			}
			
			& > img {
				width: 760px;
				height: auto;
				
				@include responsive(xs) {
					width: 100%;
					height: auto;
				}
			}
		}
	}
	
	&-studying {
		padding: 70px 0 120px 0;
		@include width(center);
		
		&-items {
			@include flex();
			flex-wrap: wrap;
			margin: -40px;
			margin-top: 0;
		}
		
		&-item {
			margin: 20px;
			
			& > img {
				width: auto;
				height: 205px;
			}
		}
	}
}