h4, h5, h6 {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

h2 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 10px;
}

p {
    font-size: 16px;
    margin-bottom: 10px;
}

ul {
    margin-bottom: 20px;
    padding-left: 20px;
}

li {
    margin-bottom: 5px;
}