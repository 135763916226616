.navigation-second {
	position: relative;
	background-color: $color-grey;
	min-width: 320px;
	padding: 25px;
	
	@include responsive(md) {		
		background-color: inherit;
		padding-bottom: 0;
		padding-left: 0;
	}
	
	&-close {
		position: fixed;
		right: 35px;
		top: 25px;
		color: $color-white;
		@include font(40, 400, 10);
		z-index: 33;
	}

	&-items {
		@include flex(column);
		align-items: flex-start;
		
		@include responsive(md) {
			display: none;
			position: fixed;
			flex-direction: column;
			background-color: rgba(0, 0, 0, 0.9);
			width: 100%;
			height: 100vh;
			top: 0;
			left: 0;
			overflow: auto;
			padding: 20px;
			z-index: 24;
		}
		
		@media screen and (min-width: 1200px) {
			display: flex !important;
		}
		
		& .menutitle {
			@include responsive(md) {
				color: $color-white !important;
			}
		}
		
		& a {
			@include responsive(md) {
				color: $color-white !important;
			}
		}
	}

	&-item {
		& .menutitle {
			color: #000000;
			text-transform: uppercase;
			@include font(15, 600, 37);
		}
	
		&-subtitles {
			@include flex(column);
			align-items: flex-start;
			margin-left: 20px;
			
			& a {
				color: #000000;
				@include font(15, 400, 33);
				
				&::first-letter {
					text-transform: capitalize;
				}
			}
		}
	}
	
	&-gamburger {	
		display: none;
		position: fixed;
		border: 1px solid;
		background-color: $color-white;
		border-radius: 4px;
		width: 40px;
		height: 40px;
		padding: 4px;
		padding-top: 0;
		right: 20px;
		top: 340px;
		cursor: pointer;
		z-index: 20;
		
		&-line {
			border-top: 4px solid;
			margin-top: 8px;
		}
		
		@include responsive(md) {
			display: block;
		}
	}
}