.article {
  $indent-space-1: 40px;

  &-image-left {
    img {
      max-width: 600px;
      width: 20vw;
      float: left;
      margin: 0 15px 15px 0;
    }

    &:after {
      content: "";
      clear: both;
      display: table;
    }
  }

  &-image-right {
    img {
      max-width: 600px;
      width: 20vw;
      float: right;
      margin: 0 0 15px 15px;
    }

    &:after {
      content: "";
      clear: both;
      display: table;
    }
  }

  h3 {
    //@include font(23, 600, 24);
    margin: 20px 0;
  }

  h4 {
    //@include font(20, 600, 24);
  }

  p {
    text-align: justify;
    word-break: break-word;
    @include font(15, 400, 20);

    &:not(:first-child) {
      margin-top: 12px;
    }

    &.indent {
      text-indent: $indent-space-1;
    }

    span {
      &.strong {
        font-weight: 800;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      list-style: disc;

      li {
        display: list-item;

        &:not(:first-child) {
          margin-top: 8px;
        }
      }

      &.indent-1 {
        margin-left: $indent-space-1 + 17px;
      }
    }
  }

  @include responsive(md) {
    &-image-left,
    &-image-right {
      img {
        width: 40vw;
      }
    }
  }

  @include responsive(sm) {
    &-image-left,
    &-image-right {
      img {
        width: 50vw;
      }
    }
  }

  @include responsive(xs) {
    &-image-left,
    &-image-right {
      img {
        width: 100%;
      }
    }
  }
}
