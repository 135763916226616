.button-line {
	position: relative;
	background-color: inherit;
	color: $color-grey_light;
	border: none;	
	padding: 0;
	@include font(15, 400, 20);
	cursor: pointer;
	
	&:hover {
		color: $color-orange;
		border-color: red;
		
		&::after {
			border-bottom: 1px solid $color-orange;
		}
	}
	
	&::after {
		position: absolute;
		content: '';
		border-bottom: 1px solid $color-grey_light;;
		width: 100%;
		left: 0;
		bottom: 0;
	}
}