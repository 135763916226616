.home-carousel {
	position: relative;

	&-logo {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: -10px;
		z-index: 1;
	}
	
	.carousel-root {
		@include responsive(sm) {
			display: none;
		}
	}
	
	&-smallscreen {
		display: none;
	
		@include responsive(sm) {
			display: block;
			object-fit: cover;
			width: 100%;
			height: 500px;
		}
	}
	
	&-title {
		position: absolute;
		color: $color-white;
		text-transform: uppercase;
		width: 100%;
		top: 20%;
		
		@include responsive(sm) {
			width: calc(100% - 40px);
			left: 10px;
		}
	}
	
	&-subtitle {	
		position: absolute;
		color: $color-white;
		text-transform: uppercase;
		width: 100%;
		top: 55%;
		
		@include responsive(sm) {
			width: calc(100% - 40px);
			left: 10px;
			top: 60%;
		}
	}
	
	&-button {
		position: absolute;
		top: 75%;
		left: 50%;
		transform: translateX(-50%);
		
		@include responsive(sm) {
			top: 80%;
		}
	}
}