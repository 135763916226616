.button-yellow {
	background-color: $color-yellow;
	color: $color-white;
	border: none;
	border-radius: 4px;
	padding: 10px 25px;
	@include font(18, 600, 24);
	cursor: pointer;
	
	&:hover {
		background-color: $color-orange;
		transition: all 0.4s;
	}
}