.networks {
	display: inline-block;
	height: 20px;	
	
	&-items {	
		@include flex();
		margin: 0 -12px;
	}
	
	&-item {
		margin: 0 12px;
	
		& > a {
			display: inline-block;
		}
		
		& > img {
			height: 100%;
			width: auto;
		}
	}
}