.header-top {
	flex-wrap: wrap;
	justify-content: space-between;
	max-width: 100%;
	padding: 0 20px;
	justify-content: space-between;
    width: 600px;
    align-items: center;
    margin: 0 auto;
	display: flex;
	
	&-small {
		max-height: 80px;
		padding: 0;
	}
	
	&-phone {
		@include flex();
		margin: 20px 10px 20px 0;
		margin-right: 80px;
		
		& > img {
			width: 14px;
			height: auto;
		}
		
		& > span {
			@include font(14, 400, 19);
			margin-left: 10px;
		}
	}
	
	&-networks {
		margin: 20px 80px 20px 0;
	}
	
	&-languages {
		@include font(14, 400, 19);
	}
}