.map {
	background-color: $color-violet;
	padding: 120px 0;
	
	&-inner {		
		@include width(center);

		@include responsive(xs) {
			width: 100%;
		}
	}
	
	&-title {
		text-transform: uppercase;
	}
	
	&-content {
		position: relative;
		height: 400px;
		margin-top: 70px;
		
		& > img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
		
		&-block {
			position: absolute;
			color: $color-white;
			@include flex(column);
			justify-content: flex-start;
			align-items: flex-start;
			width: 350px;
			background-color: rgba(65, 4, 110, 0.7);
			padding: 50px;
			right: 90px;
			top: 0;
			height: 100%;
			box-sizing: border-box;
			
			@include responsive(xs) {
				width: 100%;
				left: 0;
			}
			
			&-title {
				@include font(15, 600, 20);
			}
			
			&-phone-1,
			&-phone-2,
			&-mail {
				@include font(15, 400, 33);
			}
			
			&-location-1,
			&-location-2 {
				@include font(15, 400, 24);
			}
			
			&-networks {
				margin-top: 20px;
			}
			
			&-message {
				margin-top: 40px;
			}
		}
	}
}