// weight: 300
@font-face {
	font-family: OpenSansLight;
	src: url(../fonts/OpenSans-Light.ttf);
}

// weight: 400
@font-face {
	font-family: OpenSansRegular;
	src: url(../fonts/OpenSans-Regular.ttf);
}

// weight: 600
@font-face {
	font-family: OpenSansSemiBold;
	src: url(../fonts/OpenSans-SemiBold.ttf);
}

// weight: 700
@font-face {
	font-family: OpenSansBold;
	src: url(../fonts/OpenSans-Bold.ttf);
}