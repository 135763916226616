.team {
	&-header {
		position: relative;		
		@include flex();
		height: 370px;
		
		&-image {
			position: absolute;
			object-fit: cover;
			width: 100%;
			height: 100%;
			z-index: -1;
		}
		
		&-title {
			color: $color-white;
			text-transform: uppercase;			
			@include widthXS(center);
		}
	}
	
	&-articles {
		&-items {
			@include flex();
			flex-wrap: wrap;
			margin-top: 50px;
			margin-bottom: -20px;
		}
		
		&-item {
			@include flex();
			justify-content: space-between;
			align-items: flex-start;
			margin: 20px;
			height: 300px;
			width: 370px;
			overflow: hidden;
			
			@include responsive(xs) {
				width: 100%;
			}
			
			&-left {				
				& > img {
					object-fit: cover;
					width: 125px;
					height: 175px;
				}
			}
			
			&-right {
				padding: 0 0 15px 15px;
				flex-grow: 1;
				
				&-title {
					text-transform: uppercase;
					text-align: left;
				}
				
				&-text {
					margin-top: 10px;
					@include font(15, 400, 20);
				}
			}
		}
	}
}