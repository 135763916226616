p, span {
  &.paragraph {
    text-indent: 40px;
    font-size: 20px;
  }
}

.underline {
  text-decoration: underline;
}

//.article {
//  h5 {
//    @include font(18, 600, 24);
//  }
//}

h5 {
  &.article-title {
    @include font(18, 600, 24);
    font-size: 20px;
  }
}
