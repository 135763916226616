html {
	font-size: $font-size-root + px;
	min-width: 340px;
}

h1 {	
	@include font(44, 700, 60);
	
	
	@include responsive(sm) {
		@include font(30, 700, 40);
	}
}

h2 {
	@include font(35, 700, 48);
	
	@include responsive(sm) {
		@include font(21, 700, 20);
	}
}

h3 {
	@include font(30, 600, 41);
	
	@include responsive(sm) {
		@include font(22, 700, 20);
	}
}

h4 {
	@include font(23, 600, 31);
	
	@include responsive(sm) {
		@include font(20, 700, 24);
	}
}

h1,
h2,
h3,
h4 {
	text-align: center;
}

a {
	text-decoration: none;
	
	&:hover {
		transition: all 0.4s;
	}
}

.text {
	text-transform: uppercase;
	text-indent: 0;
}

li {
	display: inline-block;
}

.br {
	border: 1px solid;
}