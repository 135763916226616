.team-pattern {
	padding: 0 0 70px 0;

	@include responsive(xs) {
		padding: 0 0;
	}

	&-title {
		text-transform: uppercase;
	}
}
